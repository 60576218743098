import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import Highlighter from 'react-highlight-words'
import {
  Icon,
  Select,
  Button,
  Input,
  Table,
  Card
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { notification } from 'antd/lib'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { getPushPathWrapper } from '../../../routes';

class AllEmployee extends React.Component {
  state = {
    selectedRowKeys: [],
    selectedRows: [], // Check here to configure the default column
    loading: false,
    tableLoading: false,
    data: [],
    pagination: {},
    searchText: ''
  }

  componentDidMount() {
    this.apiRequest()

  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.current = pagination.current
    this.setState({
      pagination: pager
    })
    this.apiRequest({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }
  apiRequest = async (params = {}) => {
    this.setState({ loading: true })
    let data = await Request.viewSession({ ...params })
    const pagination = { ...this.state.pagination }
    pagination.total = data.data && data.data.length
    this.setState({
      loading: false,
      data: data.data,
      pagination
    })
  }
  delete = async () => {
    const { dispatch, form } = this.props
    this.setState({ loading: true })
     dispatch(showLoader())
    let ids = []
    _.forEach(this.state.selectedRows, (obj, index) => {
      ids.push(obj._id)
    })
    let x = await Request.deleteSession(ids)

    dispatch(hideLoader())
    if (!x.error) {
      notification.success({
        message: x.message,
      })
      this.setState({ selectedRowKeys: [], selectedRows: [] })
      this.apiRequest()
    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message
      })
    }
  }
  editSession = async (params) => {
    this.props.dispatch(
      getPushPathWrapper('session.editSession', { _id: params._id })
    )
  }
  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows })
  }
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  })
  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  render() {

    const columns = [
      {
        title: 'Session Name',
        key: 'sessionName',
        sorter: true,
        dataIndex: 'sessionName',
        ...this.getColumnSearchProps('sessionName')
      },
      {
        title: 'Start Date',
        key: 'startDate',
        dataIndex: 'startDate',
        render: (val, result) => {
          return <div>{moment(val).format('LL')}</div>
        }
      },
      {
        title: 'End Date',
        key: 'endDate',
        dataIndex: 'endDate',
        render: (val, result) => {
          return <div>{moment(val).format('LL')}</div>
        }
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (val, record) => {

          return (
            <div>
              <Button onClick={() => this.editSession(record)}><Icon type="edit" theme="twoTone" /></Button>
            </div>
          )
        }
      }
    ]
    const { tableLoading, selectedRowKeys } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    }
    const hasSelected = selectedRowKeys.length > 0

    return (
      <PageHeaderWrapper
        title={'Sessions List'}>
        <Button type="primary" style={{ marginBottom: 16 }} onClick={this.delete} disabled={!hasSelected}
          loading={tableLoading}>
          Delete
        </Button>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
        </span>
        <Card bordered={true}>
          <Table rowSelection={rowSelection}
            columns={columns}
            rowKey={record=>record._id}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.loading || this.state.tableLoading}
            onChange={this.handleTableChange} />
        </Card>
      </PageHeaderWrapper>)
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllEmployee)
