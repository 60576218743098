import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { getPushPathWrapper } from '../../../routes'
import { TableComp } from 'sz-react-utils'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Tag,
  Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper } from '../../../routes'
import { hideLoader, showLoader } from '../../../modules/actions';

class viewCourse extends Component {


  state = {
    data: {},
    searchText: '',
    loading: true
  }


  componentDidMount() {
    this.apiRequest();
  }
  undo = async (id) => {
    this.setState({ loading: true })
    let x = await Request.restoreCourse(id)
   this.setState({ loading: false }
      , () => {
        notification.destroy();
        this.apiRequest();
      })
  }
  apiRequest = () => {
    return new Promise(async (resolve) => {
      let data = await Request.viewCourse();
    _.forEach(data.data, (elem) => {
        elem.subjectArray = elem.subjectArray.map(sub => sub.name)
        elem.branchArray = elem.branchArray.map(sub => sub.name)

      })
      this.setState({
        data: data,
        loading: false
      })

      resolve(data.data)
    })
  }

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
            </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
            </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };
  deleteCourse = async (params) => {
    const { dispatch } = this.props;
    this.setState({ loading: true })
    dispatch(showLoader())
    const { _id } = params
   const response = await Request.deleteCourse({ _id: _id })
  
    dispatch(hideLoader())
    this.setState({ loading: false })
    if (!response.error) {
      notification.success({
        message: 'Deleted Success',
        description: response.message,
        btn: <Button onClick={() => { this.undo(_id) }}>undo</Button>
      })
      this.apiRequest()
    } else {
      notification.error({
        message: 'Error Deleting',
        description: response.message
      })
    }
  }
  editCourse = async (params) => {
    this.props.dispatch(
      getPushPathWrapper('course.editCourse', { _id: params._id })
    )
  }


  render() {
    let total = 0;
    const columns = [
      {
        title: '#',
        key: 'number',
        dataIndex: 'number',
        render: (val, record) => {
          total++
          return total
        }
      }, {
        title: 'Course Name(code)',
        dataIndex: 'code',
        key: 'code',
        width: '20%',
        ...this.getColumnSearchProps('code'),
      },
      {
        title: 'Discount',
        dataIndex: 'discount',
        key: 'discount',
        width: '10%',
        ...this.getColumnSearchProps('discount'),
      }, {
        title: 'Branch',
        dataIndex: 'branchArray',
        key: 'branchArray',
        width: '20%',
        render: (branchArray) => {
          return <div>{branchArray &&branchArray.map(sub => <Tag style={{ marginTop: '2px' }} key={sub} color="#2db7f5">{sub}</Tag>)}</div>
        },
      }, {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
        width: '15%',
        ...this.getColumnSearchProps('duration'),
      }, {
        title: 'Net Fees',
        dataIndex: 'netFees',
        key: 'netFees',
        width: '15%',
        ...this.getColumnSearchProps('netFees'),
      }, {
        title: 'Subjects',
        dataIndex: 'subjectArray',
        key: 'subjectArray',
        width: '20%',
        render: (subjectArray) => {
          return <div>{subjectArray && subjectArray.map(sub => <Tag style={{ marginTop: '2px' }} key={sub} color="#2db7f5">{sub}</Tag>)}</div>
        },
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (val, record) => {

          return (
            <div>
              <Button onClick={() => this.editCourse(record)}><Icon type="edit" theme="twoTone" /></Button>
              <Button onClick={() => this.deleteCourse(record)}><Icon type="delete" theme="twoTone" /></Button>
            </div>
          )
        }
      }
    ];

    return (
      <PageHeaderWrapper
        title={'View Course'}>
        <Card>
          <Table columns={columns} dataSource={this.state.data.data} loading={this.state.loading} />
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(viewCourse)
