import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  notification,
  Switch,
  Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getPushPathWrapper } from '../../../routes'
import { showLoader, hideLoader } from '../../../modules/actions';

class viewSubject extends Component {

  state = {
    data: null,
    loading: true
  }

  componentDidMount() {
    this.apiRequest()
  }
  undo = async (id) => {
    this.setState({ loading: true })
    let x = await Request.restoresubject(id);
    this.setState({ loading: false }, () => {
      notification.destroy();
      this.apiRequest();
    })
  }
  apiRequest = (params) => {
    return new Promise(async (resolve) => {
      const data = await Request.viewSubject({ ...params })
      this.setState({
        data: data.data,
        loading: false
      })
      console.log(data.data)
       resolve(data)
    })
  }

  deleteSubject = async (params) => {
    const { dispatch } = this.props;
    this.setState({ loading: true })
    dispatch(showLoader())
    const { _id } = params
    const response = await Request.deleteSubject({ _id: _id })
     dispatch(hideLoader());
    if (!response.error) {
      notification.success({
        message: response.message,
        btn: <Button onClick={() => this.undo(_id)}>undo</Button>
      })
      this.apiRequest()
    } else {
      notification.error({
        message: 'Error Saving',
        description: response.message
      })
    }
  }
  editSubject = async (params) => {
    this.props.dispatch(
      getPushPathWrapper('course.editSubject', { _id: params._id })
    )
  }
  render() {
    let total = 0
    const columns = [
      {
        title: '#',
        key: 'number',
        dataIndex: 'number',
        render: (val, record) => {
          total++
          return total
        }
      },
      {
        title: 'Name',
        key: 'name',
        sorter: true,
        dataIndex: 'name',
        searchTextName: 'name'
      },
      {
        title: 'Institute ID',
        dataIndex: 'instituteId.name',
        key: 'instituteId',
        searchTextName: 'instituteId'
      },
      {
        title: 'Subject Code',
        dataIndex: 'code',
        key: 'code',
        searchTextName: 'code'
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        key: 'notes',
        searchTextName: 'notes'
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        render: (val, record) => {

          return (
            <div>
              <Button onClick={() => this.editSubject(record)}><Icon type="edit" theme="twoTone" /></Button>
              <Button onClick={() => this.deleteSubject(record)}><Icon type="delete" theme="twoTone" /></Button>
            </div>
          )
        }
      }
    ]
    return (
      <PageHeaderWrapper
        title={'All Subjects'}>

        <Card bordered={true}>
          <Table columns={columns} dataSource={(this.state.data)} loading={this.state.loading} />
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(viewSubject)
