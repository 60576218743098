import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Spin,
    Card,
    Icon,
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
import { prototype } from 'stream';
import { getUrlParams } from '../../../routes';

// inputSchema.fields


const FormItem = Form.Item
const { Option } = Select


@Form.create()
class AddSession extends PureComponent {

    inputTypes = {
        fields: [
            {
                label: 'Session Name',
                key: 'sessionName',
                placeholder: 'Session Name',
                required: true
            },
            {
                label: 'Start Date',
                key: 'startDate',
                type: 'date',
                placeholder: 'Start Date',

                required: true
            },
            {
                label: 'End Date',
                key: 'endDate',
                placeholder: 'End Date',
                type: 'date',
                required: true
            },
        ]
    }


    handleSubmit = e => {
        const { dispatch, form } = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                valData.instituteId = this.props._id ? this.props._id : this.state.instituteId;
                dispatch(showLoader())
                if (!this.state.edit) {
                    let x = await Request.addSession(valData)
                    dispatch(hideLoader())

                    if (!x.error) {
                        notification.success({
                            message: 'Session added successfully'
                        })
                        this.props.form.resetFields()

                    } else {

                        notification.error({
                            message: 'Error Saving',
                            description: x.message
                        })
                    }
                } else {
                    let id = await getUrlParams('session.editSession', this.props.pathname)
                    let x = await Request.updateSession(id._id, { ...valData })
                   dispatch(hideLoader())

                    if (!x.error) {
                        notification.success({
                            message: 'Session Updated successfully'
                        })
                    } else {
                        notification.error({
                            message: 'Error Updating',
                            description: x.message
                        })
                    }

                }
            }
        })
    }

    setFormValues = async (id) => {
        this.setState({loading: true})
        let { data } = await Request.getByIdSession(id)
    
        let x = {
            sessionName: data && data.sessionName,
            startDate: data && moment(data.startDate),
            endDate: data && moment(data.endDate),
       }

        this.props.form.setFieldsValue(x)
       this.setState({ loading:false, data: data, instituteId: data.instituteId, update: true })


    }

    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            title: 'EDIT SESSION',
            instituteId: '',
            loading: false
        }



    }
    instituteChildren = [];
    getInstitutes = async () => {
        let data = await Request.getInstitutes()
        if (!data.error) {
            if (data.data) {
                _.forEach(data.data, (value, index) => {
                    this.instituteChildren.push(<Option label={value._id} value={value._id} key={value._id}>{value.name}</Option>)
                })
            }
        }
    }

    componentDidMount() {
        this.getInstitutes();
        if (this.props._id) {
            this.setState({ propsId: true })
        }
        let data = getUrlParams('session.editSession', this.props.pathname)
         if (data && data._id) {
            this.setState({
                edit: true,
                title: 'UPDATE',
                loading: true
            }, () => {
                this.setFormValues(data._id)
            })
        } else {
            this.setState({
                edit: false,
                title: 'ADD SESSION'
            }, () => {
                console.log("qwertyu")
            })
        }

    }



    render() {
        const { defaultInstituteId } = this.state
        const { submitting } = this.props
        const {
            form: { getFieldDecorator, getFieldValue }
        } = this.props

        const { editorState } = this.state

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }
        const submitFormLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 10, offset: 7 },
                md: { span: 12, offset: 8 }
            }
        }
        const handleChange = (value, key) => {
            this.setState({ [key]: value })

        }
        const { update, edit, instituteId } = this.state;
        const updateInst = () => {
            return (
                update ?
                    <Form.Item label='Institute Id ' {...formItemLayout}>
                        <Select
                            mode="single"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            defaultValue={instituteId}
                            onChange={(val) => handleChange(val, 'instituteId')
                            } >
                            {this.instituteChildren}
                        </Select>

                    </Form.Item> :
                    !edit ?
                        <Form.Item label='Institute Id ' {...formItemLayout}>
                            <Select
                                mode="single"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                onChange={(val) => handleChange(val, 'instituteId')
                                } >
                                {this.instituteChildren}
                            </Select>
                        </Form.Item> : false
            )
        }
        return (
            <PageHeaderWrapper
                title={this.state.edit ? 'EDIT SESSION' : 'ADD SESSION'}
            // content={'This is some descopt '}

            >
                <Spin tip="Loading..." spinning={this.state.loading}>
                    <Card title="Primary Details" bordered={true}>
                        {this.state.propsId === true ? false : updateInst()}
                        <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>

                            <Form.Item label='Session Name' {...formItemLayout}>
                                {getFieldDecorator('sessionName', {
                                    rules: [{ required: true, message: 'Please input your note!' }],
                                })(<Input
                                    placeholder='Enter Session Name'
                                    prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}

                                >
                                </Input>)}


                            </Form.Item>
                            <Form.Item label='Start Date' type='date' {...formItemLayout}>
                                {getFieldDecorator('startDate', {
                                    rules: [{ required: true, message: 'Please input your note!' }],
                                })(<DatePicker></DatePicker>)}

                            </Form.Item>
                            <Form.Item label='End Date' {...formItemLayout}>
                                {getFieldDecorator('endDate', {
                                    rules: [{ required: true, message: 'Please input your note!' }],
                                })(<DatePicker></DatePicker>)}

                            </Form.Item>

                            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                                <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                    {this.state.title}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Spin>
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSession)
