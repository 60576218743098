import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Input,
    TimePicker,
    Select,
    Form,
    Button,
    Card,
    Checkbox,
    Table,
    Popconfirm,
    Spin
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { getUrlParams } from '../../../routes'
import styles from './add.less'
import util from '../../../assets/util'
const { Option } = Select

@Form.create()
class AddBatch extends PureComponent {
    remainder = 30 - (moment().minute() % 30);
    dateTime = moment().add(this.remainder, "minutes")
    end = moment(this.dateTime).add(1, 'hour')

    constructor(props) {
        super(props)
    }
    columns = [
        {
            title: 'Day',
            dataIndex: 'dayName',
            width: '30%',
        },
        {
            title: 'Start Time',
            key: 'start',
            dataIndex: 'start',
            render: (start, record, key) => {
                return <div>
                    <TimePicker format="h:mm a" defaultValue={start} disabled={record.disabled}
                        onChange={(time) => this.onChange(time, key, 'start')}
                        use12Hours />

                </div>
            }
        },
        {
            title: 'End Time',
            dataIndex: 'end',
            render: (end, record, key) => {
                return <div key={record}>
                    <TimePicker format="h:mm a" defaultValue={end} disabled={record.disabled}
                        onChange={(time) => this.onChange(time, key, 'end')} use12Hours
                    />

                </div>
            }
        },
        {
            title: 'Off',
            dataIndex: 'off',
            render: (off, record, key) => {
                return <Checkbox defaultChecked={off} onChange={(e) => this.handleCheckbox(e.target, key, record)} style={{ width: '20px', height: '20px' }} />
            }
        },
    ];
    state = {
        dataSource: [
            {
                key: 0,
                day: 1,
                dayName: 'Monday',
                start: moment(this.dateTime),
                end: moment(this.end),
                off: false,
                disabled: false
            },
            {
                key: 1,
                day: 2,
                dayName: 'Tuesday',
                start: moment(this.dateTime),
                end: moment(this.end),
                off: false,
                disabled: false
            },
            {
                key: 2,
                day: 3,
                dayName: 'Wednesday',
                start: moment(this.dateTime),
                end: moment(this.end),
                off: false,
                disabled: false
            },
            {
                key: 3,
                day: 4,
                dayName: 'Thursday',
                start: moment(this.dateTime),
                end: moment(this.end),
                off: false,
                disabled: false
            },
            {
                key: 4,
                day: 5,
                dayName: 'Friday',
                start: moment(this.dateTime),
                end: moment(this.end),
                off: false,
                disabled: false
            },
            {
                key: 5,
                day: 6,
                dayName: 'Saturday',
                start: moment(this.dateTime),
                end: moment(this.end),
                off: false,
                disabled: false
            },
            {
                key: 6,
                day: 7,
                dayName: 'Sunday',
                start: moment(this.dateTime),
                end: moment(this.end),
                off: false,
                disabled: false
            }
        ],
        count: 7,
        btnLoading: false,
        coursesList: [],
        branchesList: [],
        course: '',
        branch: '',
        subjects:[],
        instituteId: null,
        edit: false,
        title: 'ADD',
        permanentChange: true
    };
    instituteChildren = [];
    courseChildren = [];
    branchChildren = [];
    subjectChildren = [];
    getInstitute = async () => {
        this.instituteChildren = await util.getInstitutes()
        this.forceUpdate()
    }
    getCourses = async () => {
        this.courseChildren = await util.getCourses({ instituteId: this.state.instituteId || this.props._id })
        this.forceUpdate()
    }
    getBranches = async () => {
        this.branchChildren = await util.getBranches({ instituteId: this.state.instituteId || this.props._id })
        this.forceUpdate()
    }
    getSubjects = async (id) => {
        let data =  await Request.getByIdCourse(id)
        if (!data.error) {
            if (data.data) {
                _.forEach(data.data.subjectArray, (value, index) => {
                    this.subjectChildren.push(<Option label={value._id} value={value._id} key={value._id}>{value.name}</Option>)
                })
            }
        }
        this.forceUpdate()
    }
    onChange = (time, key, key2) => {
        let newData = [...this.state.dataSource];
        newData[key][key2] = time
        this.setState({
            dataSource: [...newData]
        })
    }
    handleCheckbox = (target, key, record) => {
        let newData = [...this.state.dataSource];
        record.disabled = target.checked;
        newData[key].off = target.checked
        this.setState({
            dataSource: [...newData],
        })
    }
    handleSave = row => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData });
    };
    handleSubmit = e => {
        const { dispatch, form } = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                dispatch(showLoader())
                if (this.state.permanentChange) valData.dayArray = [...this.state.dataSource]
                else valData.tempArray = [...this.state.dataSource]
                valData.courseId = this.state.course
                valData.branchId = this.state.branch
                valData.subjects = this.state.subjects
                valData.instituteId = this.props._id ? this.props._id : this.state.instituteId;
                let x = {}
                if (!this.state.id) {
                    x = await Request.addBatch({ ...valData })
                } else {
                    x = await Request.editbatch(this.state.id, valData)
                }
                dispatch(hideLoader())

                if (!x.error) {
                    notification.success({
                        message: x.message
                    })
                    this.props.form.resetFields()

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }
    setFormValues = async (id) => {
        let { data } = await Request.getbatch(id);
        this.props.form.setFieldsValue({
            batchName: data.batchName,
            batchCode: data.batchCode,
        })
        let newData = [...this.state.dataSource]
        _.forEach(newData, (value, index) => {
            _.forEach(value, (val, key) => {
                if (value[key] && data.dayArray[index][key]) {
                    if (key == 'start' || key == 'end') {
                        value[key] = moment(data.dayArray[index][key])
                    }
                    else value[key] = data.dayArray[index][key]
                }
                else {
                    if (key == 'off') {
                        value[key] = data.dayArray[index][key]
                        value.disabled = data.dayArray[index][key]
                    }
                    value.className = 'permanent'
                }
            })
            if (data.tempArray && data.tempArray.length > 0) {
                _.forEach(data.tempArray, (temp, i) => {
                    if (temp.day == value.day) {
                        value.start = moment(temp.start)
                        value.end = moment(temp.end)
                        value.off = temp.off
                        value.disabled = temp.off
                        value.className = 'temp'
                    }
                })
            }
        })
        let subjects = []
        _.forEach(data.subjects, (value, index) => {
            subjects.push(value._id)
        })

        this.setState({
            dataSource: [...newData],
            instituteId: data.instituteId,
            course: data.courseId._id,
            branch: data.branchId._id,
            update: true,
            loading: false,
            subjects
        }, () => console.log(this.state))
    }
    componentDidMount() {
        if (this.props._id) {
            this.getBranches()
            this.getCourses()
            this.setState({ propsId: true, instituteId: this.props._id })
        }
        else {
            this.getInstitute()
        }
        let data = getUrlParams('batch.edit', this.props.pathname)
        if (data && data.id) {
            this.setState({
                id: data.id,
                edit: true,
                title: 'UPDATE',
                loading: true
            }, () => {
                this.setFormValues(this.state.id)
            })
        }
        else {
            this.setState({
                edit: false,
                title: 'ADD',
                loading: false
            }, () => {

            })

        }
    }
    optionChange = (value, field) => {
        this.setState({
            [field]: value
        }, () => {
            if (field === 'instituteId') {
                this.getBranches()
                this.getCourses()
            }
            if(field === 'course'){
                this.getSubjects(value)
            }
        })
    }
    confirm = (e) => {
        this.setState({
            permanentChange: true
        }, () => {
            this.props.form.submit(() => this.handleSubmit(e))
        })
    }
    cancel = (e) => {
        this.setState({
            permanentChange: false
        }, () => {
            this.props.form.submit(() => this.handleSubmit(e))
        })
    }
    render() {
        const { dataSource, btnLoading, subjects ,instituteId, update, course, branch, edit } = this.state;
        const { form: { getFieldDecorator } } = this.props
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }
        const submitFormLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 10, offset: 7 },
                md: { span: 12, offset: 8 }
            }
        }
        const updateComp = () => {
            return (
                update ?
                    <div>
                        <Form.Item label='Branch'>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={branch}
                                onChange={(val) => this.optionChange(val, 'branch')}
                            >
                                {this.branchChildren}
                            </Select>
                        </Form.Item>
                        <Form.Item label='Course'>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={course}
                                onChange={(val) => this.optionChange(val, 'course')}
                            >
                                {this.courseChildren}
                            </Select>
                        </Form.Item>
                        <Form.Item label='Subject'>
                            <Select
                                mode='multiple'
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={subjects}
                                onChange={(val) => this.optionChange(val, 'subjects')}
                            >
                                {this.subjectChildren}
                            </Select>
                        </Form.Item>
                        <Table
                            bordered
                            rowKey={record => record.day}
                            rowClassName={(record) => {
                                if (moment().day() == record.day) {
                                    return styles['current']
                                }
                                else return styles[record.className]
                            }}
                            dataSource={dataSource}
                            columns={this.columns} >
                        </Table>
                    </div>
                    : !edit ?
                        <div>
                            <Form.Item label='Branch'>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    defaultValue={branch}
                                    disabled={!instituteId}
                                    loading={btnLoading}
                                    onChange={(val) => this.optionChange(val, 'branch')}
                                >
                                    {this.branchChildren}
                                </Select>
                            </Form.Item>
                            <Form.Item label='Course'>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    disabled={!instituteId}
                                    loading={btnLoading}
                                    onChange={(val) => this.optionChange(val, 'course')}
                                >
                                    {this.courseChildren}
                                </Select>
                            </Form.Item>
                            <Form.Item label='Subject'>
                            <Select
                                mode='multiple'
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                onChange={(val) => this.optionChange(val, 'subjects')}
                            >
                                {this.subjectChildren}
                            </Select>
                        </Form.Item>
                            <Table
                                bordered
                                rowKey={record => record.day}
                                dataSource={dataSource}
                                columns={this.columns} >
                            </Table>
                        </div>
                        : <Table
                            bordered
                            dataSource={dataSource}
                            columns={this.columns} >
                        </Table>

            )
        }
        const updateInst = () => {
            return (
                update ?
                    <Form.Item label='Institute Id ' {...formItemLayout}>
                        <Select
                            mode="single"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            defaultValue={instituteId}
                            onChange={(val) => this.optionChange(val, 'instituteId')
                            } >
                            {this.instituteChildren}
                        </Select>

                    </Form.Item> :
                    !edit ?
                        <Form.Item label='Institute Id ' {...formItemLayout}>
                            <Select
                                mode="single"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                onChange={(val) => this.optionChange(val, 'instituteId')
                                }
                            >
                                {this.instituteChildren}
                            </Select>
                        </Form.Item> : false
            )
        }
        return (
            <PageHeaderWrapper title={this.state.title + ' BATCH'}>

                <Spin tip="Loading..." spinning={this.state.loading}>
                    {
                        <Card title="Primary Details" bordered={true}>
                            <Form {...formItemLayout} onSubmit={this.handleSubmit} style={{ marginTop: 8 }}>
                                {this.state.propsId === true ? false : updateInst()}
                                <Form.Item label="Batch Name">
                                    {getFieldDecorator('batchName', {
                                        rules: [{ required: true, message: 'Please input batch name!' }],
                                    })(<Input />)}
                                </Form.Item>
                                <Form.Item label="Batch Code">
                                    {getFieldDecorator('batchCode', {
                                        rules: [{ required: true, message: 'Please input batch code!' }],
                                    })(<Input />)}
                                </Form.Item>
                                {updateComp()}
                                <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                                    {edit ?
                                        <Popconfirm
                                            title="Please confirm wheather it is a permanent or temporary change?"
                                            onConfirm={this.confirm}
                                            onCancel={this.cancel}
                                            okText="Permanent"
                                            cancelText="Temporary"
                                        >
                                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                                {this.state.title}
                                            </Button>
                                        </Popconfirm> :
                                        <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                            {this.state.title}
                                        </Button>}
                                </Form.Item>
                            </Form>
                        </Card>
                    }
                </Spin>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    pathname: router.location.pathname,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddBatch)
