import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Input,
    Form,
    Select,
    Button,
    Card,
    Checkbox,
    Row,
    Col,
    InputNumber,
    Spin,
} from 'antd'
import _ from 'lodash'

import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { getUrlParams } from '../../../routes'
import util from '../../../assets/util'
// inputSchema.fields
const { Option } = Select

let inputTypes = {
    fields: [
        {
            label: 'Name',
            key: 'name',
            type: 'string',
            dataIndex: 'name',
            required: true
        },
        {
            label: 'Branch Code',
            key: 'branchCode',
            type: 'string',
            dataIndex: 'branchCode',
            required: true
        },
        {
            label: 'Branch Enquiry Code',
            key: 'branchEnqCode',
            type: 'string',
            dataIndex: 'branchEnqCode',
            required: true
        },
        {
            label: 'Branch Reg. Code',
            key: 'branchRegCode',
            dataIndex: 'branchRegCode',
            type: 'string',
            required: true
        },
        {
            label: 'Address',
            key: 'address',
            type: 'string',
            dataIndex: 'address',
            required: true
        },
        {
            label: 'Telephone No.',
            key: 'phone',
            type: 'integer',
            dataIndex: 'phone',
            required: true
        },
        {
            label: 'Mobile No.',
            key: 'mobile',
            type: 'integer',
            dataIndex: 'mobile',
            required: true
        },
        {
            label: 'Admin Notification Mobile No.',
            key: 'adminNotificationMobile',
            type: 'integer',
            dataIndex: 'adminNotificationMobile',
            required: true
        },
        {
            label: 'Email Id',
            key: 'email',
            type: 'string',
            dataIndex: 'email',
            required: true
        },
        {
            label: 'Website URL',
            key: 'website',
            type: 'string',
            dataIndex: 'website',
            required: true
        },
        {
            label: 'Service Tax',
            key: 'serviceTax',
            dataIndex: 'serviceTax',
            type: 'integer',
            required: true
        },
        {
            label: 'Service TaxNo',
            key: 'serviceTaxNo',
            dataIndex: 'serviceTaxNo',
            type: 'integer',
            required: true
        },
        {
            label: 'Status',
            key: 'status',
            dataIndex: 'status',
            type: 'string',
            required: true
        },
        {
            label: 'Receipt Type',
            key: 'receiptType',
            dataIndex: 'receiptType',
            type: 'string',
            required: true
        }
    ]
}
@Form.create()
class AddBranch extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            title: 'ADD Branch',
            instituteId: null,
        }
    }
    instituteChildren = [];
    getInstitute = async () => {
        this.instituteChildren = await util.getInstitutes()
        this.forceUpdate()
    }
    handleSubmit = e => {
        const { dispatch, form } = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                if (!this.state.edit) {
                    valData.instituteId = this.props._id ? this.props._id : this.state.instituteId;
                    dispatch(showLoader())
                    let x = await Request.addBranch({ ...valData })
                    dispatch(hideLoader())

                    if (!x.error) {
                        notification.success({
                            message: 'Branch added successfully'
                        })
                        this.props.form.resetFields()

                    } else {
                        notification.error({
                            message: 'Error Saving',
                            description: x.message
                        })
                    }

                } else {
                     let x = await Request.editBranch((getUrlParams('branch.edit', this.props.pathname)), { ...valData })
                    dispatch(hideLoader())

                    if (!x.error) {
                        notification.success({
                            message: 'branch Updated successfully'
                        })
                    } else {
                        notification.error({
                            message: 'Error Updating',
                            description: x.message
                        })
                    }
                }
            }
        })
    }
    setFormValues = async (id) => {
        let { data } = await Request.getBranch(id)

        let x = {
            name: data.name,
            address: data.address,
            phone: parseInt(data.phone),
            mobile: parseInt(data.mobile),
            email: data.email,
            website: data.website,
            adminNotificationMobile: parseInt(data.adminNotificationMobile),
            branchCode: data.branchCode,
            branchEnqCode: data.branchEnqCode,
            branchRegCode: data.branchRegCode,
            serviceTax: parseInt(data.serviceTax),
            serviceTaxNo: parseInt(data.serviceTaxNo),
            status: data.status,
            receiptType: data.receiptType

        }


        this.props.form.setFieldsValue(x)
        this.setState({
            loading: false,
            instituteId: data.instituteId,
            update: true,
        })

    }
    componentDidMount() {
        this.getInstitute()
        if (this.props._id) {
            this.setState({ propsId: true })
        }
        let data = getUrlParams('branch.edit', this.props.pathname)
         if (data && data.id) {
            this.setState({
                edit: true,
                title: 'Update',
                loading: true
            }, () => {
                this.setFormValues(data.id)
            })
        } else {
            this.setState({
                edit: false,
                title: 'ADD branch',
                loading: false
            })
        }
    }
    optionChange = (value, field) => {
        this.setState({
            [field]: value
        })
    }
    render() {
        const { form: { getFieldDecorator } } = this.props
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 8 }
            }
        }
        const submitFormLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 10, offset: 7 },
                md: { span: 12, offset: 8 }
            }
        }
        const formData =inputTypes.fields && inputTypes.fields.map((data,index) => {
            const notReq=['adminNotificationMobile','branchEnqCode','website','serviceTax','serviceTaxNo','receiptType']
            return (
                <Form.Item label={data.label} dataIndex={data.dataIndex} >
                    {getFieldDecorator(data.dataIndex, {
                        rules: [{ required: !notReq.includes(data.dataIndex), type: data.type }],
                    })(data.type == 'integer' ? <InputNumber style={{ width: '100%' }} /> : <Input />)}
                </Form.Item>

            )
        })
        const {update, edit, instituteId} = this.state;
        const updateInst = () => {
            return (
                update ?
                    <Form.Item label='Institute ' {...formItemLayout}>
                        <Select
                            mode="single"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            defaultValue={instituteId}
                            required
                            onChange={(val) => this.optionChange(val, 'instituteId')
                            } >
                            {this.instituteChildren}
                        </Select>

                    </Form.Item> :
                    !edit ?
                        <Form.Item label='Institute Id ' {...formItemLayout}>
                            <Select
                                mode="single"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                              
                                onChange={(val) => this.optionChange(val, 'instituteId')
                                } >
                                {this.instituteChildren}
                            </Select>
                        </Form.Item> : false
            )
        }
        return (
            <PageHeaderWrapper title={this.state.edit ? 'Update branch' : 'Add branch'}>
                <Spin tip="Loading..." spinning={this.state.loading}>
                    <Card title="Primary Details" bordered={true}>
                        <Form {...formItemLayout} onSubmit={this.handleSubmit}  style={{ marginTop: 8 }}>
                            {this.state.propsId === true ? false : updateInst()}
                            {formData}
                            <Form.Item label="Main Branch" dataIndex="isMain">
                                {getFieldDecorator('isMain', {
                                })(<Checkbox />)}
                            </Form.Item>

                            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                                <Button type="primary" htmlType="submit" loading={this.props.loading} >
                                    {!this.state.edit ? 'Add Branch' : 'Update Branch'}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Spin>
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddBranch)
