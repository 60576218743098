import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  DatePicker,
  Select,
  Button,
  Card,
  Icon
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import util from '../../../assets/util'

// inputSchema.fields
// const {form: {getFieldDecorator, getFieldValue, setFieldsValue}} = this.props


const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AddUser extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      instituteId: null,
      branchesLoad: false
    }
  }
  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {

        dispatch(showLoader())
        valData.branchId = this.state.branchId;
        valData.instituteId = this.props._id ? this.props._id : this.state.instituteId;
        valData.userType = 'user'
        let x = await Request.addUser(valData)

        dispatch(hideLoader())

        if (!x.error) {
          notification.success({
            message: 'Users added successfully'
          })
          this.props.form.resetFields()

        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message
          })
        }

      }
    })
  }

  setFormValues = async (slug) => {

    let { data } = await Request.getWebsite(slug)

    this.setState({
      extraFeilds: data.extraUrls.length
    })

    let x = {
      url: data.url,
      category: data.category,
      tags: data.tags,
      description: data.description,
      baseColor: data.baseColor,
      logoBgColor: data.logoBgColor,
      logoUrl: data.logoUrl,
      projectDate: moment(data.projectDate)
    }

    _.each(data.extraUrls, (val, k) => {
      x[`extraUrl-${k}`] = val
    })

    this.props.form.setFieldsValue(x)

  }

  componentDidMount() {
    this.getInstitutes()
    if (this.props._id) {
      this.getBranches()
      this.setState({ propsId: true,instituteId: this.props._id })
    }
  }
  branchChildren = []
  instituteChildren = []
  getInstitutes = async () => {
    this.instituteChildren = await util.getInstitutes();
    this.forceUpdate()
  }
  getBranches = async ()=>{
    this.setState({branchesLoad: true})
    this.branchChildren = await util.getBranches({ instituteId: this.state.instituteId||this.props._id  });
    this.setState({branchesLoad: false})
  }
  render() {
    let inputTypes = {
      fields: [
        {
          label: 'Full Name',
          key: 'name',
          required: true
        },
        {
          label: 'Email',
          key: 'email',
          required: true
        },
        {
          label: 'Password',
          key: 'password',
          type: 'password',
          required: true
        }
      ]
    }
    const { submitting } = this.props
    const {
      form: { getFieldDecorator, getFieldValue, setFieldsValue }
    } = this.props

    const { instituteId, branchesLoad } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }
    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }
    const handleChange = (value, key) => {
      this.setState({ [key]: value},()=>{
        if(key === 'instituteId') {
          this.getBranches()
        }
      })
    }

    return (
      <PageHeaderWrapper
        title={'Add User'}
        content={'add the user'}
      >

        <Card bordered={true}>
          <Form onSubmit={this.handleSubmit} style={{ marginTop: 8 }}>
            {this.state.propsId === true ? false : <Form.Item label='Institute Id ' {...formItemLayout}>
              <Select
                mode="single"
                style={{ width: '100%' }}
                placeholder="Please select"
                onChange={(val) => handleChange(val, 'instituteId')
                } >
                {this.instituteChildren}
              </Select>

            </Form.Item>}
            <Form.Item label='Branch ' {...formItemLayout}>
              <Select
                mode="single"
                style={{ width: '100%' }}
                disabled = {!instituteId}
                placeholder="Please select"
                loading = {branchesLoad}
                onChange={(val) => handleChange(val, 'branchId')}
              >
                {this.branchChildren}
              </Select>

            </Form.Item>
            <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
              getFieldDecorator={getFieldDecorator} />

            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button type="primary" htmlType="submit" loading={this.props.loading}>
                SAVE
              </Button>
            </Form.Item>

          </Form>
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddUser)
