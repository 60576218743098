import React from 'react'
import _ from 'lodash'
import { Select } from 'antd'
import Request from '../request'
const {Option} = Select;
export default {
    getSubjects : async (filter) => {
        let data = await Request.getSubjects(filter);
        let arr = []
        if (!data.error) {
            if (data.data) {
                _.forEach(data.data, (value, index) => {
                    arr.push(<Option label={value._id} value={value._id} key={value._id}>{value.name}</Option>)
                })
            }
        }
        return arr;
    },
    getSessions : async (filter) => {
        let data = await Request.getSessions(filter);
        console.log(data.data)
        let arr = []
        if (!data.error) {
            if (data.data) {
                _.forEach(data.data, (value, index) => {
                    arr.push(<Option label={value._id} value={value._id} key={value._id}>{value.sessionName}</Option>)
                })
            }
        }
        console.log(arr)
        return arr;
    },
    getCourses : async (filter) => {
        let data = await Request.getCourses(filter);
        let arr = []
        if (!data.error) {
            if (data.data) {
                _.forEach(data.data, (value, index) => {
                    arr.push(<Option label={value._id} value={value._id} key={value._id}>{value.name}</Option>)
                })
            }
        }
        return arr;
    },
    getBranches : async (filter) => {
        let data = await Request.getBranches(filter);   
        console.log(data,filter)   
        let arr = []
        if (!data.error) {
            if (data.data) {
                _.forEach(data.data, (value, index) => {
                    arr.push(<Option label={value._id} value={value._id} key={value._id}>{value.name}</Option>)
                })
            }
        }
        return arr;
    },
    getInstitutes : async (filter) => {
        let data = await Request.getInstitutes(filter);  
        let arr = []
        if (!data.error) {
            if (data.data) {
                _.forEach(data.data, (value, index) => {
                    arr.push(<Option label={value._id} value={value._id} key={value._id}>{value.name}</Option>)
                })
            }
        }
        return arr;
    },
    getBatches : async (filter) => {
        let data = await Request.getbatches(filter);
        let arr = []
        if (!data.error) {
            if (data.data) {
                _.forEach(data.data, (value, index) => {
                    arr.push(<Option label={value._id} value={value._id} key={value._id}>{value.batchName}</Option>)
                })
            }
        }
        return arr;
    },
    getCurrentSession : async (filter) => {
        let data = await Request.getCurrentSession(filter)        
        return data.data[0];
    }
}