import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { Form, Button, Card, message, Avatar, Upload } from 'antd'
import _ from 'lodash'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'

import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { uploadUrl } from '../../../settings'
import { getUrlParams } from '../../../routes'

// inputSchema.fields


@Form.create()
class AddInstitute extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      logoUrl: null,
      uploadLogo: null,
      uploadPrintLogo: null
    }
  }
  inputTypes = {
    fields: [
      {
        label: 'Name',
        key: 'name',
        dataIndex: 'name',
        required: true
      },
      {
        label: 'Code',
        key: 'code',
        dataIndex: 'code',
        required: true
      },
      {
        key: 'address',
        dataIndex: 'address',
        required: true
      },
      {
        key: 'city',
        dataIndex: 'city',
        required: true
      },
      {
        key: 'state',
        dataIndex: 'state',
        required: true
      },
      {
        key: 'pinCode',
        dataIndex: 'pinCode',
        required: true
      },
      {
        label: 'Email Id',
        key: 'email',
        dataIndex: 'email',
        required: true
      },
      {
        label: 'Password',
        key: 'password',
        type: 'password',
        dataIndex: 'password',
        required: true
      },
      {
        label: 'Mobile No.',
        key: 'mobile',
        dataIndex: 'mobile',
        required: true
      },
      {
        label: 'pan No.',
        key: 'panNo',
        dataIndex: 'panNo',

      },
      {
        label: 'Website URL',
        key: 'website',
        dataIndex: 'website',
      },

      {
        key: 'displayName',
        dataIndex: 'displayName',

      },

    ]
  }
  handleSubmit = e => {
    const { dispatch, form } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        dispatch(showLoader())
        const iconLogo = this.state.LogoData && {
          ...this.state.LogoData.file,
          tag: 'institute'
        }
        const printLogo = this.state.printLogoData && {
          ...this.state.printLogoData.file,
          tag: 'institute'
        }
        let x = {}
        this.state.edit ?
          x = await Request.updateInstitute(this.state.id, { ...valData, iconLogo: iconLogo && iconLogo, printLogo: printLogo && printLogo })
          :
          x = await Request.addInstitute({ ...valData, iconLogo: iconLogo && iconLogo, printLogo: printLogo && printLogo })
        dispatch(hideLoader())

        if (!x.error) {
          notification.success({
            message: x.message
          })
          if (!this.state.edit) this.props.form.resetFields()
        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message
          })
        }
      }
    })
  }
  getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  handleChangeLogo = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loading: true })
      return
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.  
      this.getBase64(info.file.originFileObj, logoUrl =>
        this.setState(
          {
            logoUrl,
            loading: false,
            LogoData: info.file.response,
            uploadLogo: true
          },
          () => {
          }
        )
      )
    }
  }

  handleChangePrintLogo = info => {
    if (info.file.status === 'uploading') {
      this.setState({ loadingP: true })
      return
    }
    if (info.file.status === 'done') {
      this.getBase64(info.file.originFileObj, printLogoUrl =>
        this.setState(
          {
            printLogoUrl,
            loadingP: false,
            printLogoData: info.file.response,
            uploadPrintLogo: null
          },
          () => {
          }
        )
      )
    }
  }
  componentDidMount() {
    let data = getUrlParams('institute.editInstitute', this.props.pathname)
    if (data && data._id) {
      this.inputTypes.fields.splice([this.inputTypes.fields.length - 2], 1)
      this.setState({
        edit: true,
        id: data._id,
        title: 'Update',
        loading: true
      }, () => {
        this.setFormValues(data._id)
      })
    } else {
      this.setState({
        edit: false,
        title: 'ADD',
        loading: false
      })
    }
  }
  setFormValues = async (id) => {
    this.setState({ loading: true })
    let { data } = await Request.getInstitute(id)
    this.setState({ loading: false })
    let x = {}
    _.forEach(this.inputTypes.fields, (obj, key) => {
      x[obj.dataIndex] = data[obj.dataIndex]
    })
    this.props.form.setFieldsValue(x)

    this.setState({
      update: true,
      loading: false,
      logoUrl: data.iconLogo.location,
      printLogoUrl: data.printLogo.location
    },
    )
  }

  render() {
    function beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!')
      }
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        message.error('Image must smaller than 2MB!')
      }
      return isJpgOrPng && isLt2M
    }

    const uploadButton = (
      <div>
        <Avatar
          shape="square"
          size={60}
          icon={this.state.loading ? 'loading' : 'upload'}
        />
      </div>
    )
    const uploadButtonP = (
      <div>
        <Avatar
          shape="square"
          size={60}
          icon={this.state.loadingP ? 'loading' : 'upload'}
        />
      </div>
    )
    const {
      form: { getFieldDecorator }
    } = this.props
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }
    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }
    const { logoUrl, printLogoUrl } = this.state

    return (
      <PageHeaderWrapper title={`${this.state.title} Institute`}>
        <Card title="Primary Details" bordered={true}>
          <Form
            onSubmit={this.handleSubmit}

            style={{ marginTop: 8 }}>
            <GetAllFormFields
              inputSchema={this.inputTypes}
              formItemLayout={formItemLayout}
              getFieldDecorator={getFieldDecorator}
            />
            <Form.Item label="Logo" {...formItemLayout}>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={uploadUrl}
                beforeUpload={beforeUpload}
                onChange={this.handleChangeLogo}>
                {logoUrl ? (
                  <img src={logoUrl} alt="avatar" style={{ width: '100%' }} />
                ) : (
                    uploadButton
                  )}
              </Upload>
            </Form.Item>
            <Form.Item label="Print logo" {...formItemLayout}>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action={uploadUrl}
                beforeUpload={beforeUpload}
                onChange={this.handleChangePrintLogo}>
                {printLogoUrl ? (
                  <img src={printLogoUrl} alt="avatar" style={{ width: '100%' }} />
                ) : (
                    uploadButtonP
                  )}
              </Upload>
            </Form.Item>
            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.props.loading}>
                {this.state.title}
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  pathname: router.location.pathname
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddInstitute)
