import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import Highlighter from 'react-highlight-words'
import {
  Drawer,
  Menu,
  Dropdown,
  Icon,
  Tag,
  Button,
  Input,
  Table,
  Card
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import { notification } from 'antd/lib'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { getPushPathWrapper } from '../../../routes';
import Adduser from '../../users/add'
import Addbatch from '../../batch/add'
import Addbranch from '../../branch/add'
import Addcourse from '../../course/add'
import Addsession from '../../session/add'
class AllInstitute extends React.Component {
  state = {
    visible: false,
    selectedRowKeys: [],
    selectedRows: [], // Check here to configure the default column
    loading: false,
    tableLoading: false,
    data: [],
    pagination: {},
    searchText: ''
  }

  componentDidMount() {
    this.apiRequest()
    this.getBranches()
    this.getCourses()
    this.getBatches()
  }
  getCourses = async () => {
    let data = await Request.getCourses();
    if (!data.error) {
      this.setState({ coursesList: data.data })
    }
  }
  getBatches = async () => {
    let data = await Request.getbatches();
    if (!data.error) {
      this.setState({ batchesList: data.data })
    }
  }
  getBranches = async () => {
    let data = await Request.getBranches();
    if (!data.error) {
      this.setState({ branchesList: data.data })
    }
  }
  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
    this.getBranches()
    this.getCourses()
    this.getBatches()
    this.apiRequest()
  };
  menu = (id) => (
    <Menu onClick={(item) => this.handleChange(item, id)}>
      <Menu.Item key='user'>
        <div>Add User</div>
      </Menu.Item>
      <Menu.Item key='session'>
        <div>Add Session</div>
      </Menu.Item>
      <Menu.Item key='branch'>
        <div>Add Branch</div>
      </Menu.Item>
      <Menu.Item key='batch'>
        <div>Add Batch</div>
      </Menu.Item>
      <Menu.Item key='course'>
        <div>Add Course</div>
      </Menu.Item>
    </Menu>
  );
  handleChange = (item, _id) => {
    this.setState({
      visible: true,
      item: { ...item, _id }
    });
  }
  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination }
    pager.current = pagination.current
    this.setState({
      pagination: pager
    })
    this.apiRequest({
      results: pagination.pageSize,
      page: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters
    })
  }
  apiRequest = async (params = {}) => {
    this.setState({ tableLoading: true })
    let data = await Request.getInstitutes({ ...params, regExFilters: ['sessionName'] })
    const pagination = { ...this.state.pagination }
    pagination.total = data.data.length
    this.setState({
      loading: false,
      tableLoading: false,
      data: data.data,
      pagination
    })
  }
  delete = async () => {
    const { dispatch, form } = this.props
    this.setState({ loading: true })
    // ajax request after empty completing
    dispatch(showLoader())
    let ids = []
    _.forEach(this.state.selectedRows, (obj, index) => {
      ids.push(obj._id)
    })
   let x = await Request.deleteInstitute(ids)

    dispatch(hideLoader())
    if (!x.error) {
      notification.success({
        message: x.message,
        // btn: <Button>undo</Button>
      })
      this.setState({ selectedRowKeys: [], selectedRows: [] })
      this.apiRequest()
    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message
      })
    }
  }

  onSelectChange = (selectedRowKeys, selectedRows) => {
    this.setState({ selectedRowKeys, selectedRows })
  }
  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    )
  })
  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }
  editinstitute = async (_id) => {
    this.props.dispatch(
      getPushPathWrapper('institute.editInstitute', { _id:_id })
    )
  }
  render() {

    const columns = [
      {
        title: ' Name',
        key: 'Name',
        sorter: true,
        dataIndex: 'name',
        fixed: 'left',
        width: 100,
        ...this.getColumnSearchProps('name')
      },
      {
        title: 'Code',
        key: 'Code',
        dataIndex: 'code',
      },
      {
        title: 'Address',
        key: 'address',
        dataIndex: 'address',
      },
      {
        title: 'State',
        key: 'state',
        dataIndex: 'state',

      },
      {
        title: 'City',
        key: 'city',
        dataIndex: 'city',

      },
      {
        title: 'Website URL',
        key: 'WebsiteURL',
        dataIndex: 'website',

      },
      {
        title: 'Email Id',
        key: 'EmailId',
        dataIndex: 'email',

      },
      {
        title: 'Mobile',
        key: 'mobile',
        dataIndex: 'mobile',

      },
      {
        title: 'Branches',
        key: 'branches',
        dataIndex: '_id',
        render: (_id) => {
          let branches = []
          if (this.state.branchesList) {
            branches = this.state.branchesList.map((branch, index) => {
              if (branch.instituteId._id === _id) {
                return <Tag key={index}>{branch.name}</Tag>
              }
            })
          }
          return branches
        }
      },
      {
        title: 'Courses',
        key: 'courses',
        dataIndex: '_id',
        render: (_id) => {
          let branches = []
          if (this.state.coursesList) {
            branches = this.state.coursesList.map((course, index) => {
              if (course.instituteId === _id) {
                return <Tag key={index}>{course.name}</Tag>
              }
            })
          }
          return branches
        }
      },
      {
        title: 'Batches',
        key: 'batches',
        dataIndex: '_id',
        render: (_id) => {
          let batches = []
          if (this.state.batchesList) {
            batches = this.state.batchesList.map((batch, index) => {
              if (batch.instituteId === _id) {
                return <Tag key={index}>{batch.batchName}</Tag>
              }
            })
          }
          return batches
        }
      },
      {
        title: 'Action',
        key: 'operation',
        width: 100,
        dataIndex: '_id',
        render: (_id) => {
          return (
            <div>
              <Dropdown overlay={() => this.menu(_id)}>
                <Icon type="plus-circle" style={{ fontSize: '20px', cursor: 'pointer' }} />
              </Dropdown>
              <Icon onClick={() => this.editinstitute(_id)} type="edit" style={{marginLeft:'1em'}} />
            </div>
          )
        },
        fixed: 'right'
      }
    ]
    const { tableLoading, loading,selectedRowKeys, item, visible } = this.state
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    }
    const hasSelected = selectedRowKeys.length > 0
    const condition = (key, _id) => {
      return key == 'user' ? <Adduser _id={_id} /> : key == 'batch' ? <Addbatch _id={_id} /> : key == 'branch' ? <Addbranch _id={_id} /> : key == 'course' ? <Addcourse _id={_id} /> : <Addsession _id={_id} />
    }
    return (
      <PageHeaderWrapper
        title={'Institute List'}>
        <Button type="primary" style={{ marginBottom: 16 }} onClick={this.delete} disabled={!hasSelected}
          loading={loading}>
          Delete
        </Button>
        <span style={{ marginLeft: 8 }}>
          {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
        </span>
        <Card bordered={true}>
          <Table bordered rowSelection={rowSelection}
            columns={columns}
            rowKey={(record) => record._id}
            scroll={{ x: 1300 }}
            dataSource={this.state.data}
            pagination={this.state.pagination}
            loading={this.state.tableLoading}
            onChange={this.handleTableChange}
          />
        </Card>

        <Drawer
          title={`Create a new ${item && item.key}`}
          width={720}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          {item ? condition(item.key, item._id) : false}
        </Drawer>
      </PageHeaderWrapper>)
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllInstitute)
