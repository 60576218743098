import axios from 'axios'

import { apiUrl } from './settings'
import { resolve } from 'url';

export const API_URL = apiUrl

let authAxios = axios.create({
    baseURL: apiUrl
    
})

let getToken = () => {
    return ({ 'headers': { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
}


class Request {

    constructor() {

    }
    addInstitute(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/institute', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    console.log(err)
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    updateInstitute(id,data) {
        return new Promise((next, error) => {
            authAxios
                .put(`/institute/${id}`, data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    getInstitute(id){
        return new Promise((resolve) => {
            authAxios
                .get(`/institute/${id}`, getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteInstitute(_ids) {
        return new Promise((resolve) => {
            authAxios
                .delete(`/institute`, { data: { _ids } }, getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    

    error = (err) => {
        try {
            if (err.response.status === 401) {
                localStorage.clear()
            }
        } catch (e) {
        }
    }

    login(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/login', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    getAllUser(data) {
        return new Promise((next) => {
            authAxios
                .get('/user', { params: { ...data } }, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }

    addUser(data) {
        return new Promise((next) => {
            authAxios
                .post('/user', { ...data }, getToken())
                .then((d) => {
                    console.log(d.data)
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    addSession(data) {
        return new Promise((resolve, error) => {
            authAxios
                .post('/session', data)
                .then((d) => {
                    resolve(d.data)
                })
                .catch((err) => {
                    console.log(err)
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    addBatch(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/batch', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    viewSession(data) {
        return new Promise((resolve, error) => {
            console.log("109", data)
            authAxios
                .get('/session/all', {params: data})
                .then((d) => {
                    console.log("113", d.data)
                    resolve(d.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        })
    }
    getCurrentSession(data) {
        return new Promise((resolve, error) => {
            console.log("109", data)
            authAxios
                .get('/session/current', {params: data})
                .then((d) => {
                    console.log("113", d.data)
                    resolve(d.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        })
    }
    getbatches() {
        return new Promise((resolve) => {
            authAxios
                .get('/batch/all', getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteSession(_ids) {
        return new Promise((resolve) => {
            console.log(_ids)
            authAxios
                .delete('/session', { data: { _ids } }, getToken())
                .then((d) => {
                    console.log(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })

        })
    }

    getbatch(id) {
        return new Promise((resolve) => {
            authAxios
                .get(`/batch/${id}`, getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateSession(find, update) {
        return new Promise((resolve, error) => {
            authAxios
                .put('/session/' + find, { ...update })
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }

    editbatch(id, data) {
        return new Promise((resolve) => {
            authAxios
                .put(`/batch/${id}`, { ...data }, getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    getByIdSession(id) {
        return new Promise((resolve, error) => {
            console.log("109", id)
            authAxios
                .get(`/session/${id}`, getToken())
                .then((d) => {
                    console.log("113", d.data)
                    resolve(d.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        })
    }
    deletebatch(_ids) {
        return new Promise((resolve) => {
            authAxios
                .delete(`/batch`, { data: { _ids } }, getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    getSubjects() {
        return new Promise((resolve) => {
            authAxios
                .get('/subject/all', getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    getInstitutes() {
        return new Promise((resolve) => {
            authAxios
                .get('/institute/all', getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    updateCourse(find, update) {
        return new Promise((next, error) => {
            authAxios
                .put(`/course/${find._id}`, {...update })
                .then((d) => {
                    console.log(d.data)
                    next(d.data)
                })
                .catch((err) => {
                    console.log(err)
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    addCourse(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/course', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    console.log(err)

                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    viewCourse(data) {
        return new Promise((resolve) => {
            console.log(data)
            authAxios
                .get('/course/all', { params: { ...data } })
                .then((d) => {
                    console.log(d.data)
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })

        }
        )
    }
    getByIdCourse(id) {
        return new Promise((resolve, error) => {
            console.log("109", id)
            authAxios
                .get(`/course/${id}`, getToken())
                .then((d) => {
                    console.log("113", d.data)
                    resolve(d.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        })
    }
    restorebatch(_ids) {
        return new Promise((resolve) => {
            authAxios
                .patch(`/batch`, { _ids }, getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteCourse(_ids) {
        return new Promise((next) => {
            authAxios
                .delete('/course', { data: _ids }, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    restoreCourse(_id) {
        return new Promise((resolve) => {
            authAxios
                .patch(`/course`, { _id }, getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    getCourses(data) {
        return new Promise((resolve) => {
            authAxios
                .get('/course/all',{params: {...data}} ,getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    getBranches(data) {
        return new Promise((resolve) => {
            authAxios
                .get('/branches',{params:{...data}}, getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    addBranch(data) {
        console.log(data);
        return new Promise((resolve) => {
            authAxios
                .post('/branch', data)
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    addSubject(data) {
        return new Promise((next, error) => {
            authAxios
                .post('/subject', data)
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    console.log(err)
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    viewSubject(data) {
        return new Promise((next) => {
            authAxios
                .get('/subject/all', { params: { ...data } }, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    getByIdSubject(id) {
        return new Promise((resolve, error) => {
            console.log("109", id)
            authAxios
                .get(`/subject/${id}`, getToken())
                .then((d) => {
                    console.log("113", d.data)
                    resolve(d.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        })
    }
    updateSubject(find, update) {
        return new Promise((next, error) => {
            authAxios
                .put('/subject/' + find, { ...update })
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    console.log(err)
                    next({ error: true, err })
                    this.error(err)
                })

        })
    }
    deleteSubject(_id) {
        return new Promise((next) => {
            authAxios
                .delete('/subject', { data: { _id } }, getToken())
                .then((d) => {
                    next(d.data)
                })
                .catch((err) => {
                    next({ error: true, err })
                    this.error(err)
                })
        })
    }
    restoresubject(_id) {
        return new Promise((resolve) => {
            authAxios
                .patch(`/subject`, { _id }, getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    getBranch(id) {
        return new Promise((resolve) => {
            authAxios
                .get(`/branch/${id}`, getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    editBranch(id, data) {
        console.log(id)
        return new Promise((resolve) => {
            authAxios
                .put(`/branch/${id.id}`, { ...data }, getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    deleteBranch(_ids) {
        return new Promise((resolve) => {
            authAxios
                .delete(`/branch`, { data:{_ids} }, getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    }
    restoreBranch(_ids){
        return new Promise((resolve) => {
            authAxios
                .patch(`/branch`, {  _ids }, getToken())
                .then((d) => {
                    resolve(d.data)
                })
                .catch(err => {
                    resolve({ error: true, err })
                    this.error(err)
                })
        })
    } 
    
}


export default new Request()
