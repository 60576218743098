import { push } from 'connected-react-router'
import _ from 'lodash'
import Path from 'path-parser'
import { lazy } from 'react'
import Dashboard from './containers/dashboard'

import Undercons from './containers/undercons'

import AllUsers from './containers/users/all'
import AddUsers from './containers/users/add'
import AddInstitute from './containers/institute/add'

import AddBatch from './containers/batch/add'
import AllBatch from './containers/batch/all'
import AddBranch from './containers/branch/add'
import AllBranch from './containers/branch/all'

import AddSession from './containers/session/add'
import ViewSession from './containers/session/view'
import AddCourse from './containers/course/add'
import viewCourse from './containers/course/view'
import AddSubject from './containers/subject/add'
import ViewSubject from './containers/subject/view'
import viewInstitute from './containers/institute/all'
import branch from './containers/branch/add'

const menu = [
  {
    'path': '/dashboard',
    'name': 'Dashboard',
    'icon': 'dashboard',
    'key': 'dashboard',
    'homepage': true,
    'component': Dashboard,
    'authority': [
      'admin',
      'user'
    ]
  },
  {
    'path': '/users',
    'name': 'Users',
    'icon': 'user',
    'key': 'users',
    'component': Undercons,
    'dontShowOnMenu': true,
    'authority': [
      'admin',
      'user'
    ],
    'children': [
      {
        'path': '/users/add',
        'name': 'Add User',
        'title': 'Add User',
        'component': AddUsers,
        'dontShowOnMenu': true,
      },
      {
        'path': '/users/all',
        'name': 'All Users',
        'title': 'All Users',
        'component': AllUsers
      }
    ]
  },
  {
    'path': '/institute',
    'name': 'Institute',
    'icon': 'user',
    'key': 'institute',
    'component': Undercons,
    'authority': [
      'admin',
      'user'
    ],
    'children': [
      {
        'path': '/institute/add',
        'name': 'Add Institute',
        'title': 'Add Institute',
        'component': AddInstitute
      },
      {
        'path': '/institute/edit/:_id',
        'name': 'Edit Institute',
        'title': 'Edit Institute',
        'key': 'editInstitute',
        'component': AddInstitute,
        'dontShowOnMenu': true
      },
      {
        'path': '/institute/view',
        'name': 'View Institute',
        'title': 'View Institute',
        'component': viewInstitute
      }
    ]
  },
  {
    'path': '/session',
    'name': 'Session',
    'icon': 'user',
    'key': 'session',
    'children': [
      {
        'path': '/session/addSession',
        'name': 'Add Session',
        'title': 'Add Session',
        'dontShowOnMenu': true,
        'component': AddSession
      }, {
        'path': '/session/view',
        'name': 'View Sessions',
        'title': 'View Sessions',
        'component': ViewSession
      },
      {
        'path': '/session/editSession/:_id',
        'key': 'editSession',
        'name': 'Edit Session',
        'title': 'Edit Session',
        'component': AddSession,
        'dontShowOnMenu': true
      }]
  },
  {
    'path': '/course',
    'name': 'Course',
    'icon': 'user',
    'key': 'course',
    'authority': [
      'admin',
      'user'
    ],

    'children': [
      {
        'path': '/course/addCourse',
        'name': 'Add Course',
        'title': 'Add Course',
        'component': AddCourse
      },
      {
        'path': '/course/viewCourse',
        'name': 'View Course',
        'title': 'View Course',
        'component': viewCourse
      },
      {
        'path': '/course/editCourse/:_id',
        'key': 'editCourse',
        'name': 'Edit Course',
        'title': 'Edit Course',
        'component': AddCourse,
        'dontShowOnMenu': true
      }, {
        'path': '/course/addSubject',
        'name': 'Add Subject',
        'title': 'Add Subject',
        'component': AddSubject
      },
      {
        'path': '/course/editSubject/:_id',
        'key': 'editSubject',
        'name': 'Edit Subject',
        'title': 'Edit Subect',
        'component': AddSubject,
        'dontShowOnMenu': true
      },
      {
        'path': '/course/viewSubject',
        'name': 'View Subject',
        'title': 'View Subject',
        'component': ViewSubject
      }
    ],

  },
  {
    'path': '/batch',
    'name': 'Batch',
    'icon': 'user',
    'key': 'batch',
    'component': Undercons,
    'authority': [
      'admin',
      'user'
    ], 'children': [{
      'path': '/batch/add',
      'name': 'Add Batch',
      'title': 'Add Batch',
      'component': AddBatch
    },
    {
      'path': '/batch/edit/:id',
      'name': 'Edit Batch',
      'key': 'edit',
      'title': 'Edit Batch',
      'component': AddBatch,
      'dontShowOnMenu': true
    },
    {
      'path': '/batch/all',
      'name': 'All Batch',
      'title': 'All Batch',
      'component': AllBatch
    }
    ]
  },
  {
    'path': '/branch',
    'name': 'Branch',
    'icon': 'user',
    'key': 'branch',
    'component': Undercons,
    'authority': [
      'admin',
      'user'
    ],
    'children': [
      {
        'path': '/branch/add',
        'name': 'Add branch',
        'title': 'Add branch',
        'component': AddBranch
      },
      {
        'path': '/branch/edit/:id',
        'name': 'Edit branch',
        'key': 'edit',
        'title': 'Edit branch',
        'component': AddBranch,
        'dontShowOnMenu': true
      },
      {
        'path': '/branch/all',
        'name': 'All branch',
        'title': 'All branch',
        'component': AllBranch
      }
    ]
  },
]

//<editor-fold desc="Functions Exports">
export const getUrlPushWrapper = (keyString, query) => {
  return push(getUrlPath(keyString, query))
}

export const getUrlPath = (keyString, params) => {

  if (!params) params = {}

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  let queryString = Object.keys(params).
    map(key => key + '=' + params[key]).
    join('&')

  return `${val.path}?${queryString}`
}

export const getPushPathWrapper = (keyString, params) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return push(path.build(params))
  }

  return 'error'
}

export const getUrlParams = (keyString, route) => {

  let obj = getUrlObject(keyString)

  if (obj) {
    const path = new Path(obj.path)

    return path.test(route)
  }

  return { error: true }
}

export const getUrlObject = (keyString) => {

  let keyArr = keyString.split('.')

  let val = _.find(menu, p => p.key === keyArr[0])

  if (!val) {
    return `/`
  }

  if (keyArr.length === 2) {
    val = _.find(val.children, p => p.key === keyArr[1])
  }

  if (!val) {
    return `/`
  }

  return val
}
//</editor-fold>

export default menu

