export default {}

export const pageTitle = 'MIM Admin'
export const subTitle = 'Powered by we'
//export const apiUrl = `http://carsd2c.scizers.com`
// export const apiUrl = `http://medmatrix.wss.io`
// export const apiUrl = `http://localhost:8055/admin`
// export const uploadUrl = 'http://localhost:8055/client/upload/file'
export const uploadUrl = 'http://mim-restapi.scizers.com/client/upload/file'
export const apiUrl = `https://mim-restapi.scizers.com/admin`
