import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Icon,
    Spin
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
import { getUrlParams } from '../../../routes'
import TextArea from 'antd/lib/input/TextArea';
import util from '../../../assets/util'
let inputTypes = {
    fields: [
        {
            label: 'Subject Name',
            key: 'name',
            required: true
        },
        {
            label: 'Subect Code',
            key: 'code',
            required: true
        },
        {
            label: 'Notes',
            key: 'notes',
            type: 'textArea',
            placeholder: 'Notes'
        },]
}

const FormItem = Form.Item
const { Option } = Select

@Form.create()
class AddSubject extends PureComponent {

    handleSubmit = e => {
        const { dispatch, form } = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                dispatch(showLoader())
                if (!this.state.edit) {
                    let x = await Request.addSubject({ ...valData, instituteId: this.props._id ? this.props._id : this.state.instituteId })

                    dispatch(hideLoader())

                    if (!x.error) {
                        notification.success({
                            message: 'Subject added successfully'
                        })
                        let field = {
                            name: '',
                            code: '',
                            notes: ''
                        }
                        this.props.form.setFieldsValue(field)

                    } else {
                        notification.error({
                            message: 'Error Saving',
                            description: x.message
                        })
                    }
                } else {
               let x = await Request.updateSubject((getUrlParams('course.editSubject', this.props.pathname)), { ...valData })
                  dispatch(hideLoader())

                    if (!x.error) {
                        notification.success({
                            message: 'Subject Updated successfully'
                        })
                    } else {
                        notification.error({
                            message: 'Error Updating',
                            description: x.message
                        })
                    }
                }


            }
        })
    }

    setFormValues = async (id) => {

        let { data } = await Request.getByIdSubject(id)
         let x = {
            name: data.name,
            code: data.code,
            notes: data.notes
        }
        this.props.form.setFieldsValue(x)
        this.setState({
            loading: false,
            instituteId: data.instituteId,
            update: true
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            edit: false,
            title: 'Add Subject',
            loading: false,
            instituteId: null
        }


    }
    getInstitutes = async () => {
        this.instituteChildren = await util.getInstitutes()
        this.forceUpdate()        
    }
    componentDidMount() {
        if (this.props._id) {
            this.setState({ propsId: true,instituteId: this.props._id })
        }
        else{
            this.getInstitutes()
        }
        let data = getUrlParams('course.editSubject', this.props.pathname)
      if (data && data._id) {
            this.setState({
                edit: true,
                title: 'Update',
                loading: true
            }, () => {
                this.setFormValues(data._id)
            })
        } else {
            this.setState({
                edit: false,
                title: 'Add Subject',
                loading: false
            })
        }
    }

    render() {

        const { submitting } = this.props
        const {
            form: { getFieldDecorator, getFieldValue }
        } = this.props

        const { update, instituteId,edit } = this.state

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }
        const handleChange = (value, key) => {
            this.setState({ [key]: value },()=>{
            })
        }
        const updateInst = () => {
            return (
                update ?
                    <Form.Item label='Institute Id ' {...formItemLayout}>
                        <Select
                            mode="single"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            defaultValue={instituteId}
                            onChange={(val) => handleChange(val, 'instituteId')
                            } >
                            {this.instituteChildren}
                        </Select>

                    </Form.Item> :
                    !edit ?
                        <Form.Item label='Institute Id ' {...formItemLayout}>
                            <Select
                                mode="single"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                onChange={(val) => handleChange(val, 'instituteId')
                                } >
                                {this.instituteChildren}
                            </Select>
                        </Form.Item> : false
            )
        }
        const submitFormLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 10, offset: 7 },
                md: { span: 12, offset: 8 }
            }
        }

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 }
            }
        }

        return (
            <PageHeaderWrapper
                title={(this.state.edit) ? 'Update Subject' : 'Add Subject'}
            // content={'This is some descopt '}
            >

                <Spin tip="Loading..." spinning={this.state.loading}>
                    <Card title="Primary Details" bordered={true}>
                        <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>
            {updateInst()}
                            {/* <GetAllFormFields inputSchema={inputTypes} formItemLayout={formItemLayout}
                            getFieldDecorator={getFieldDecorator} /> */}
                            <Form.Item label='Subject Name' {...formItemLayout}>
                                {getFieldDecorator('name', {
                                    rules: [{ required: true, message: 'Please input your note!' }],
                                })(<Input placeholder='Enter Subject Name'></Input>)}
                            </Form.Item>
                            <Form.Item label='Subject Code' {...formItemLayout}>
                                {getFieldDecorator('code', {
                                    rules: [{ required: true, message: 'Please input your note!' }],
                                })(<Input placeholder='Enter Subject Code'></Input>)}


                            </Form.Item>
                            <Form.Item label='Subject Notes' {...formItemLayout}>
                                {getFieldDecorator('notes', {
                                
                                })(<TextArea placeholder='Enter Subject Notes'></TextArea>)}


                            </Form.Item>

                            <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                                <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                    {this.state.title}
                                </Button>
                            </Form.Item>

                        </Form>
                    </Card>
                </Spin>
            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    pathname: router.location.pathname,
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddSubject)
