import React, { Component } from 'react'

class Dashboard extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    return (
      <div className={'asdf'}>
        <h1>This is Dashboard here</h1>
        <p>Did you get here via Redux?</p>
      </div>
    )
  }

}

export default Dashboard

