import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    DatePicker,
    Select,
    Button,
    Card,
    Icon,
    InputNumber,
    Table,
    Popconfirm,
    Spin,
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment, { fn } from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
import { stat } from 'fs';
import { getUrlParams } from '../../../routes'
import TextArea from 'antd/lib/input/TextArea';
import util from '../../../assets/util'

// inputSchema.fields



const FormItem = Form.Item
const { Option } = Select

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {
        editing: false,
        disabled: false,
        disabled1: false,
        disabled2: false
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ],
                    initialValue: record[dataIndex],
                })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
            </Form.Item>
        ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                >
                    {children}
                </div>
            );
    };
    
    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                        children
                    )}
            </td>
        );
    }
}


@Form.create()
class addCourse extends PureComponent {

    handleSubmit = e => {
        const { dispatch, form } = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {

            if (!err) {
                dispatch(showLoader())
                if (!this.state.edit) {
                    valData.instituteId = this.props._id ? this.props._id : this.state.instituteId;
                    valData.installment = [...this.state.dataSource];
                    valData.subjectArray = [...this.state.subjects];
                    valData.branchArray = [...this.state.branches]
                   let x = await Request.addCourse(valData)
                    dispatch(hideLoader())

                    if (!x.error) {
                        notification.success({
                            message: 'Course added successfully'
                        })
                        this.props.form.setFieldsValue({})

                    } else {
                        notification.error({
                            message: 'Error Saving',
                            description: x.message
                        })
                    }

                } else {
                    const obj = {
                        number: valData.number,
                        afterMonth: valData.afterMonth,
                        amount: valData.amount,
                        reminderBefore: valData.reminderBefore
                    }
                    delete valData.number;
                    delete valData.afterMonth;
                    delete valData.amount;
                    delete valData.reminderBefore;

                    valData.installment = [...this.state.dataSource];
                    valData.subjectArray = [...this.state.subjects];
                    valData.branchArray = [...this.state.branches]
                    let x = {}
                    if (valData.discountType == 'none') {
                        valData.discount = 0
                      x = await Request.updateCourse((getUrlParams('course.editCourse', this.props.pathname)), { ...valData })
                    }else{
                         x = await Request.updateCourse((getUrlParams('course.editCourse', this.props.pathname)), { ...valData })
                    }
                   dispatch(hideLoader())

                    if (!x.error) {
                        notification.success({
                            message: 'Course Updated successfully'
                        })
                    } else {
                        notification.error({
                            message: 'Error Updating',
                            description: x.message
                        })
                    }
                }





            }
        })
    }

    setFormValues = async (id) => {
        let x = {}
        this.setState({loading: true})
        let { data } = await Request.getByIdCourse(id)
        console.log(data)
        this.setState({loading: false})
       
        let arraySubjects = []
        _.forEach(data.subjectArray, (value, index) => {
            arraySubjects.push(value._id)
        })
        let arrayBranches = []
        _.forEach(data.branchArray, (value, index) => {
            arrayBranches.push(value._id)
        })
        if (data.discount == 0) {
            this.setState({ disabled1: false })
            _.each(data, (value, key) => {
                if (key == 'discount') {
                    return;
                }
                x[key] = value;
            })

        } else {
            this.setState({ disabled1: true })
            _.forEach(data, (value, key) => {
                x[key] = value;
            })

        }
        this.props.form.setFieldsValue(x)
      this.setState({
            data: data,
            dataSource: data.installment,
            count: data.installment.length,
            subjects: arraySubjects,
            branches: arrayBranches,
            instituteId: data.instituteId,
            update: true,
            loading: false
        }, () => console.log(this.state))

       
    }

    constructor(props) {
        super(props)

        this.columns = [
            {
                title: 'number',
                dataIndex: 'number',
                width: '30%',
                editable: true,
            },
            {
                title: 'After Month',
                dataIndex: 'afterMonth',
                editable: true
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                editable: true
            },

            {
                title: 'Reminder Before',
                dataIndex: 'reminderBefore',
                editable: true
            },
            {

                title: 'Operations',

                render: (text, record) =>
                    this.state.dataSource.length >= 1 ? (
                        <Popconfirm title="Sure to delete?" onConfirm={() => this.handleDelete(record.number)}>
                            <a>Delete</a>
                        </Popconfirm>
                    ) : null,
                
                },
        ];
        this.state = {
            edit: false,
            title: 'ADD COURSE',
            dataSource: [],
            branches: [],
            subjects: [],
            instituteId: null,
            count: 0,
            loading: false,
            dataLoading: false
        }
    }
    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    }
    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };
    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ],
                    initialValue: record[dataIndex],
                })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
            </Form.Item>
        ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{ paddingRight: 24 }}
                    onClick={this.toggleEdit}
                >
                    {children}
                </div>
            );
    };
    


    handleDelete = key => {
        const dataSource = [...this.state.dataSource];
        this.setState({ dataSource: dataSource.filter(item => item.number !== key) });
    };

    handleAdd = () => {
        const { count, dataSource } = this.state;
        const newData = {
            number: count + 1,
            afterMonth: 1,
            amount: 32,
            reminderBefore: 'adqa',
        };
        this.setState({
            dataSource: [...dataSource, newData],
            count: count + 1,
        });
    };

    handleSave = row => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.number === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData });
    };

    subjectChildren = []
    branchChildren = []
    instituteChildren = []
    getSubjects = async () => {
        this.subjectChildren = await util.getSubjects({instituteId: this.state.instituteId||this.props._id})
        this.forceUpdate()
    }
    getBranches = async () => {
        this.branchChildren = await util.getBranches({instituteId: this.state.instituteId||this.props._id})
        this.forceUpdate()
    }
    getInstitutes = async () => {
        this.instituteChildren = await util.getInstitutes()
        this.forceUpdate()        
    }
    calcFees = () => {
        setTimeout(() => {
            let totalfees = this.props.form.getFieldValue('totalFees');
            let discounttype = this.props.form.getFieldValue('discountType');
            let discount = this.props.form.getFieldValue('discount')

            if (discounttype == 'Rs.') {
                let total = (totalfees - discount)
                if (isNaN(total)) {
                    this.props.form.setFieldsValue({ netFees: totalfees })
                } else {
                    this.props.form.setFieldsValue({ netFees: total })
                }
            }
            else if (discounttype == '%') {
                let total1 = parseInt(totalfees - (totalfees * discount / 100))
                if (isNaN(total1)) {
                    this.props.form.setFieldsValue({ netFees: totalfees })
                } else {
                    this.props.form.setFieldsValue({ netFees: total1 })
                }
            }
            else if (discounttype == 'none') {

                let netFees1 = this.props.form.getFieldValue('totalFees');
                this.props.form.setFieldsValue({ netFees: netFees1 })
                this.props.form.setFieldsValue({ discount: null }, () => {
                })
                this.setState({ disabled1: false })
            }
            this.forceUpdate()
        }, 10)
    }

    handleFees = (e, val) => {
        if (e == 'discount-type') {
            if (this.state.edit) {
                if (val == 'none') {
                    this.props.form.setFieldsValue({ discount: 0 })
                    this.setState({ disabled2: true })

                    this.calcFees()
                }
                else {
                    this.setState({ disabled1: true })
                    this.calcFees()
                }
            } else if (!this.state.edit && val == 'none') {
                this.setState({ disabled2: true })
                this.calcFees()
            }
            else {
                this.setState({ disabled1: true })
                this.calcFees()
            }
        }
        else if (e == 'total-fees') {
            this.setState({ disabled: true })
            this.calcFees()
        }
        else {
            this.setState({ disabled2: true })
            this.calcFees()
        }
    }

    componentDidMount() {
        if (this.props._id) {
            this.getBranches()
            this.getSubjects()
            this.setState({ propsId: true,instituteId: this.props._id })
        }
        else{
            this.getInstitutes()
        }
        let data = getUrlParams('course.editCourse', this.props.pathname)
        if (data && data._id) {
            this.setState({
                edit: true,
                title: 'Update',
                loading: true
            }, () => {
                this.setFormValues(data._id)
            })
        } else {
            this.setState({
                edit: false,
                title: 'ADD COURSE',
                loading: false
            })
        }
    }

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        const { submitting } = this.props
        const {
            form: { getFieldDecorator, getFieldValue, setFields }
        } = this.props

        const { editorState } = this.state

        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
                md: { span: 8 }
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
                md: { span: 12 }
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 10, offset: 7 },
                md: { span: 12, offset: 8 }
            }
        }

        const handleChange = (value, key) => {
            this.setState({ [key]: value },()=>{
                if(key === 'instituteId'){
                    this.setState({dataLoading:true})
                    this.getSubjects()
                    this.getBranches()
                    this.setState({dataLoading:false})
                }
            })
        }

        const { dataSource, update, edit, instituteId, loading,dataLoading } = this.state;
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        const updateInst = () => {
            return (
                update ?
                    <Form.Item label='Institute Id ' {...formItemLayout}>
                        <Select
                            mode="single"
                            style={{ width: '100%' }}
                            placeholder="Please select"
                            defaultValue={instituteId}
                            onChange={(val) => handleChange(val, 'instituteId')
                            } >
                            {this.instituteChildren}
                        </Select>

                    </Form.Item> :
                    !edit ?
                        <Form.Item label='Institute Id ' {...formItemLayout}>
                            <Select
                                mode="single"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                onChange={(val) => handleChange(val, 'instituteId')
                                } >
                                {this.instituteChildren}
                            </Select>
                        </Form.Item> : false
            )
        }
        const updateComp = () => {
            return (
                this.state.update ?
                    <div>
                        <Form.Item label='Branch' {...formItemLayout}>
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={this.state.branches}
                                loading={dataLoading}
                                onChange={(val) => handleChange(val, 'branches')}
                            >
                                {this.branchChildren}
                            </Select>

                        </Form.Item>
                        <Form.Item label='Subjects' {...formItemLayout}>
                            <Select
                                mode="multiple"
                                style={{ width: '100%' }}
                                placeholder="Please select"
                                defaultValue={this.state.subjects}
                                loading={dataLoading}
                                onChange={(val) => handleChange(val, 'subjects')}
                            >
                                {this.subjectChildren}
                            </Select>
                        </Form.Item>
                    </div>
                    : !this.state.edit ?
                        <div>
                            <Form.Item label='Branch' {...formItemLayout}>
                                {getFieldDecorator('branchArray', {
                                    rules: [{ required: true, message: 'Please input your note!' }],
                                })(<Select
                                    mode="multiple"
                                    style={{ width: '100%' }}
                                disabled={!instituteId}
                                loading={dataLoading}
                                    placeholder="Please select"
                                    onChange={(val) => handleChange(val, 'branches')}
                                >
                                    {this.branchChildren}
                                </Select>)}

                            </Form.Item>
                            <Form.Item label='Subjects' {...formItemLayout}>
                                {getFieldDecorator('subjectArray', {
                                    rules: [{ required: true, message: 'Please input your note!' }],
                                })
                                    (<Select
                                        mode="multiple"
                                        style={{ width: '100%' }}
                                disabled={!instituteId}
                                loading={dataLoading}
                                        placeholder="Please select"
                                        onChange={(val) => handleChange(val, 'subjects')}
                                    >
                                        {this.subjectChildren}
                                    </Select>)}
                            </Form.Item>
                        </div>
                        : false

            )
        }
        return (
            <PageHeaderWrapper
                title={this.state.edit ? 'Update Course' : 'Add Course'}
            >

                <Card loading={loading} title="Primary Details" bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark style={{ marginTop: 8 }}>
                        {this.state.propsId === true ? false : updateInst()}
                        <Form.Item label='Name' {...formItemLayout}>
                            {getFieldDecorator('name', {
                                rules: [{ required: true, message: 'Please input your note!' }],
                            })(<Input />)}

                        </Form.Item>
                        <Form.Item label='Code' {...formItemLayout}>
                            {getFieldDecorator('code', {
                                rules: [{ required: true, message: 'Please input your note!' }],
                            })(<Input />)}

                        </Form.Item>
                        <Form.Item label='Duration' {...formItemLayout}>
                            {getFieldDecorator('duration', {
                             
                            })(<Input />)}

                        </Form.Item>
                        <Form.Item label='Eligibility' {...formItemLayout}>
                            {getFieldDecorator('eligibility', {
                             
                            })(<Input />)}

                        </Form.Item>
                        <Form.Item label='Notes' {...formItemLayout}>
                            {getFieldDecorator('notes', {
                           
                            })(<TextArea />)}

                        </Form.Item>
                        {updateComp()}
                        <Form.Item label='Registration Fees' {...formItemLayout}>
                            {getFieldDecorator('registrationFees', {
                              
                            })(<InputNumber />)}

                        </Form.Item>
                        <Form.Item label='Examination Fees' {...formItemLayout}>
                            {getFieldDecorator('examinationFees', {
                              
                            })(<InputNumber />)}

                        </Form.Item>
                        <Form.Item label='Study Material Fees' {...formItemLayout}>
                            {getFieldDecorator('studyMaterialFees', {
                            
                            })(<InputNumber />)}

                        </Form.Item>


                        <Form.Item label='Total Fees' {...formItemLayout}>
                            {getFieldDecorator('totalFees', {
                                rules: [{ required: true, message: 'Please input your note!' }],
                            })(<InputNumber key='totalFees' onChange={()=>this.handleFees('total-fees')} />)}

                        </Form.Item>
                        {this.state.disabled ? <Form.Item label='Discount Type' {...formItemLayout}>
                            {getFieldDecorator('discountType', {
                                rules: [{ required: true, message: 'Please input your note!' }],
                            })(
                                <Select onChange={(e)=>this.handleFees('discount-type',e)} key='discountType'>
                                    <Option value='Rs.'>Rs.</Option>
                                    <Option value='%'>%</Option>
                                    <Option value='none'>none</Option>

                                </Select>)}
                        </Form.Item> : this.state.edit ? <Form.Item label='Discount Type' {...formItemLayout}>
                            {getFieldDecorator('discountType', {
                                rules: [{ required: true, message: 'Please input your note!' }],
                            })(
                                <Select onChange={(e)=>this.handleFees('discount-type',e)} key='discountType'>
                                    <Option value='Rs.'>Rs.</Option>
                                    <Option value='%'>%</Option>
                                    <Option value='none'>none</Option>

                                </Select>)}
                        </Form.Item> : null}
                        
                        {this.state.disabled1 ? <Form.Item label='Discount' {...formItemLayout}>
                            {getFieldDecorator('discount', {
                                rules: [{ required: true, message: 'Please input your note!' }],
                            })(<InputNumber key='discount' onChange={()=>this.handleFees('discount')} />)}
                        </Form.Item> : this.state.edit && this.state.disabled1? <Form.Item label='Discount' {...formItemLayout}>
                            {getFieldDecorator('discount', {
                                rules: [{ required: true, message: 'Please input your note!' }],
                            })(<InputNumber key='discount' onChange={()=>this.handleFees('discount')} />)}
                        </Form.Item> : null}
                        
                           {this.state.disabled2 ?  <Form.Item label='Net Fees' {...formItemLayout}>
                            {getFieldDecorator('netFees', {
                                rules: [{ required: true, message: 'Please input your note!' }],
                            })(<InputNumber style={{ fontWeight: 'Semibold', backgroundColor: '#FFFFFF', color: '#000' }} disabled key='netFees' onChange={()=>this.handleFees('net-fees')} />)}
                        </Form.Item> : this.state.edit ?  <Form.Item label='Net Fees' {...formItemLayout}>
                            {getFieldDecorator('netFees', {
                                rules: [{ required: true, message: 'Please input your note!' }],
                            })(<InputNumber style={{ fontWeight: 'Semibold', backgroundColor: '#FFFFFF', color: '#000' }} disabled key='netFees' onChange={()=>this.handleFees('net-fees')} />)}
                        </Form.Item> : null} 
                       

                        <Button onClick={this.handleAdd} type="primary" style={{ marginBottom: 16 }}>
                            Add a row
        </Button>
                        <Table
                            components={components}
                            rowClassName={() => 'editable-row'}
                            bordered
                            rowKey={(record) => record._id}
                            dataSource={dataSource}
                            columns={columns}
                        />



                        <Form.Item {...submitFormLayout} style={{ marginTop: 32 }}>
                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                {this.state.title}
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({ global, router }) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(addCourse)
